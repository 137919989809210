const LoadingROI = () => {
  return (
    <div
      className="load-7 fixed right-0 top-0 z-20 flex size-full flex-col items-center justify-center bg-[rgba(0,0,0,0.5)]"
      style={{ width: "calc(100% - 63px - 390px)" }}
    >
      <div className="square-holder z-20 opacity-100">
        <div className="square size-5 rounded-sm bg-[#4b9cdb]" />
        <span className="text-white opacity-100">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingROI;
