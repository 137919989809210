import React from "react";
import Image from "next/image";

import BackgroundImg from "~/planet-earth-background.jpg";
import Loader from "@/common/components/Loading/Loader";

const LoadingBG = () => {
  return (
    <>
      <span className="fixed left-1/2 top-40 z-10 flex -translate-x-1/2 flex-col items-center justify-center gap-10 bg-transparent">
        <div className="flex items-center justify-center">
          <Image
            src={"/assets/lumir-logo-white.png"}
            width={300}
            height={300}
            alt="Lumir Logo"
            className="mx-auto rounded-xl p-4"
          />
        </div>
        <span className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text pt-4 text-2xl font-extrabold text-transparent md:text-3xl lg:text-4xl xl:text-5xl">
          LUMIR SARDIP PLATFORM
        </span>
        <Loader style={"text-white size-10"} />
      </span>
      <Image
        src={BackgroundImg}
        alt="Loading Background"
        loading="eager"
        width={3840}
        height={2160}
        style={{
          objectFit: "cover",
        }}
        className="fixed bottom-0 opacity-50"
      />
    </>
  );
};

export default LoadingBG;
