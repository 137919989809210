import React from "react";

type UsernameValidationProps = {
  username: string;
};

// const isLongEnough = (username: string) => username.length >= 3;
const isTooLong = (username: string) => username.length > 20;
const hasValidCharacters = (username: string) =>
  /^[a-z0-9가-힣]*$/.test(username);

const UsernameValidation: React.FC<UsernameValidationProps> = ({
  username,
}) => {
  return (
    <div className="text-sm text-gray-600">
      <ul>
        {/*<li*/}
        {/*  className={`mt-1 ${isLongEnough(username) ? "text-green-600" : "text-red-600"}`}*/}
        {/*>*/}
        {/*  {isLongEnough(username)*/}
        {/*    ? "✅ Username is at least 3 characters long"*/}
        {/*    : "❌ Username must be at least 3 characters long"}*/}
        {/*</li>*/}
        <li
          className={`mt-1 ${!isTooLong(username) ? "text-green-600" : "text-red-600"}`}
        >
          {isTooLong(username) &&
            // ? "✅ Username is not too long"
            "❌ Username must be at most 20 characters long"}
        </li>
        <li
          className={`mt-1 ${hasValidCharacters(username) ? "text-green-600" : "text-red-600"}`}
        >
          {hasValidCharacters(username)
            ? "✅ Username contains only lowercase letters and numbers"
            : "❌ Username can only contain lowercase letters and numbers"}
        </li>
      </ul>
    </div>
  );
};

export default UsernameValidation;
