import React from "react";
import { AlertCircle, Check, MapSearch, NormalClose } from "@repo/ui/icons";

export enum ToastType {
  SUCCESS,
  ERROR,
  WARN,
  SEARCH,
}

const icons = [
  {
    type: ToastType.SUCCESS,
    icon: <Check width="12" height="12" />,
    iconStyle:
      "bg-palette-green p-1 rounded-full text-white flex items-center justify-center",
    textStyle: "text-gray-500",
  },
  {
    type: ToastType.ERROR,
    icon: <NormalClose width="14" height="14" />,
    iconStyle:
      "bg-palette-red p-1 rounded-full text-white flex items-center justify-center",
    textStyle: "text-gray-500",
  },
  {
    type: ToastType.WARN,
    icon: <AlertCircle width="14" height="14" />,
    iconStyle:
      "bg-palette-yellow p-1 rounded-full text-white flex items-center justify-center",
    textStyle: "text-gray-500",
  },
  {
    type: ToastType.SEARCH,
    icon: <MapSearch width="14" height="14" />,
    iconStyle:
      "bg-palette-cyan p-1 rounded-full text-white flex items-center justify-center",
    textStyle: "text-gray-500",
  },
];

interface RequestToast {
  type: ToastType;
  content: string;
}

const Toast = ({ type, content }: RequestToast) => {
  const { icon, iconStyle, textStyle } = icons[type];

  return (
    <div role="alert" className="rounded-xl bg-white p-1">
      <div className="flex items-start gap-4">
        <span className={iconStyle}>{icon}</span>

        <div className="flex-1">
          <strong className={`text-[14px] font-bold ${textStyle}`}>
            {content}
          </strong>
        </div>
      </div>
    </div>
  );
};

export default Toast;
