import React from "react";

type PasswordMatchValidationProps = {
  password: string;
  verifyPassword: string;
};

const PasswordMatchValidation: React.FC<PasswordMatchValidationProps> = ({
  password = "",
  verifyPassword = "",
}) => {
  const doPasswordsMatch = password === verifyPassword;
  const showValidation = password.length > 0 && verifyPassword.length > 0;

  if (!showValidation) return null;

  return (
    <div className="text-sm">
      <p className={doPasswordsMatch ? "text-green-600" : "text-red-600"}>
        {doPasswordsMatch ? "✅ Passwords match" : "❌ Passwords do not match"}
      </p>
    </div>
  );
};

export default PasswordMatchValidation;
