import Cookies from 'js-cookie'

const fetchAPI = async <T>(
  API_URL: string,
  options: RequestInit = {},
  serverAccessToken?: string
): Promise<T> => {
  let accessToken: string | undefined

  if (typeof window === 'undefined') {
    // 서버 사이드
    accessToken = serverAccessToken
  } else {
    // 클라이언트 사이드
    accessToken = Cookies.get('accessToken')
  }

  // console.log("accessToken", accessToken);

  const response = await fetch(`${API_URL}`, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...options.headers,
    },
  })

  const data = await response.json()

  if (!response.ok) {
    throw new Error(data.message || 'API 요청 중 오류가 발생했습니다.')
  }

  return data
}

export default fetchAPI
