import React from "react";

type EmailValidationProps = {
  email: string;
};

const isValidEmail = (email: string) =>
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
const hasDomain = (email: string) => /\.[a-z]+$/.test(email);

const EmailValidation: React.FC<EmailValidationProps> = ({ email }) => {
  return (
    <div className="text-sm text-gray-600">
      <ul>
        <li
          className={`mt-1 ${hasDomain(email) ? "text-green-600" : "text-red-600"}`}
        >
          {hasDomain(email)
            ? "✅ Email has a valid domain"
            : "❌ Email must have a valid domain (e.g., .com, .org)"}
        </li>
        <li
          className={`mt-1 ${isValidEmail(email) ? "text-green-600" : "text-red-600"}`}
        >
          {isValidEmail(email)
            ? "✅ Email format is valid"
            : "❌ Email format is invalid"}
        </li>
      </ul>
    </div>
  );
};

export default EmailValidation;
