import React from "react";

type SignupPasswordConfirmProps = {
  passwordValue: string;
};

const isShort = (password: string) => password.length < 6;
const hasUpperCase = (password: string) => /[A-Z]/.test(password);
const hasSpecialCharacter = (password: string) =>
  /[!@#$%^&*(),.?":{}|<>]/.test(password);
const isLongEnough = (password: string) => password.length >= 6;
const isTooLong = (password: string) => password.length > 20;

const SignupPasswordConfirm = ({
  passwordValue,
}: SignupPasswordConfirmProps) => {
  return (
    <div className="text-sm text-gray-600">
      <ul>
        <li
          className={`mt-1 ${isShort(passwordValue) ? "text-red-600" : "text-green-600"}`}
        >
          {isShort(passwordValue)
            ? "❌ Password is too short"
            : "✅ Password is at least 6 characters long"}
        </li>
        <li
          className={`mt-1 ${hasUpperCase(passwordValue) ? "text-green-600" : "text-red-600"}`}
        >
          {hasUpperCase(passwordValue)
            ? "✅ Password contains at least one uppercase letter"
            : "❌ Password must contain at least one uppercase letter"}
        </li>
        <li
          className={`mt-1 ${hasSpecialCharacter(passwordValue) ? "text-green-600" : "text-red-600"}`}
        >
          {hasSpecialCharacter(passwordValue)
            ? "✅ Password contains at least one special character"
            : "❌ Password must contain at least one special character"}
        </li>
        <li
          className={`mt-1 ${
            isLongEnough(passwordValue) && !isTooLong(passwordValue)
              ? "text-green-600"
              : "text-red-600"
          }`}
        >
          {isShort(passwordValue)
            ? "❌ Password must be at least 6 characters long"
            : isTooLong(passwordValue)
              ? "❌ Password is too long"
              : "✅ Password is within acceptable length"}
        </li>
      </ul>
    </div>
  );
};

export default SignupPasswordConfirm;
