import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@repo/ui/components/ui/input";
import { Label } from "@repo/ui/components/ui/label";
import { Button } from "@repo/ui/components/ui/button";
import { Alert, AlertDescription } from "@repo/ui/components/ui/alert";
import { Loader2 } from "lucide-react";

const schema = z.object({
  email: z.string().email("❌ Invalid email address"),
});

const ForgotPasswordForm = ({ toggleIsPasswordForget }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    setError("");

    try {
      const response = await fetch("/api/auth/forgot-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: data.email }),
      });

      const result = await response.json();

      if (result.success) {
        setIsSubmitted(true);
      } else {
        setError(result.message || "Failed to send email. Please try again.");
      }
    } catch (error) {
      console.error("Error sending temporary password:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="mt-8">
        <Alert>
          <AlertDescription>
            A temporary password has been sent to your email address. <br />{" "}
            Please check your inbox.
            <p className="text-center text-sm text-gray-400 mt-4">
              <span
                className="underline cursor-pointer"
                onClick={() => toggleIsPasswordForget(false)}
              >
                Return to login
              </span>
            </p>
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-white mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
    >
      <h1 className="text-xl text-transparent font-extrabold text-gray-900 sm:text-2xl md:text-3xl text-center bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text">
        LUMIR SARDIP PLATFORM
      </h1>

      <div>
        <Label
          htmlFor="email"
          className="block text-sm font-medium text-gray-600"
        >
          Email address
        </Label>
        {/*<label*/}
        {/*  htmlFor="email"*/}
        {/*  className="block text-sm font-medium text-gray-600"*/}
        {/*>*/}
        {/*  Email address*/}
        {/*</label>*/}
        <div className="mt-1">
          <Input
            id="email"
            type="email"
            autoComplete="email"
            required
            {...register("email")}
            className={`text-black ${errors.email ? "border-red-500" : ""}`}
          />
          {errors.email && (
            <p className="mt-2 text-sm text-red-500">{errors.email.message}</p>
          )}
        </div>
      </div>

      {error && <p className="text-sm text-red-500">{error}</p>}

      <div>
        <Button
          type="submit"
          className="w-full"
          disabled={!isValid || isLoading}
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Sending...
            </>
          ) : (
            "Issue temporary password"
          )}
        </Button>
      </div>

      <p className="text-center text-sm text-gray-400">
        Remember your password?{" "}
        <span
          className="underline cursor-pointer"
          onClick={() => toggleIsPasswordForget(false)}
        >
          Back to login
        </span>
      </p>
    </form>
  );
};

export default ForgotPasswordForm;
