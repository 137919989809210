import { AuthFormProps } from "./LoginForm";
import LoginInput from "./LoginInput";
import SignupPasswordConfirm from "@/app/api/auth/signin/components/SignupPasswordConfirm";
import UsernameValidation from "./UsernameValidation";
import EmailValidation from "./EmailValidation";
import PasswordMatchValidation from "./PasswordMatchValidation";
import {
  FieldErrors,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { Loader } from "@repo/common/Loading";
import React from "react";

type SignUpFormProps = Omit<AuthFormProps, "toggleIsPasswordForget"> & {
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  errors: FieldErrors<FieldValues>;
  isSubmitting: boolean;
  handleSignupSubmit: (data: FieldValues) => Promise<void>;
  watch: UseFormWatch<FieldValues>;
};

const SignUpForm = ({
  toggleIsSignUp,
  register,
  handleSubmit,
  errors,
  isSubmitting,
  handleSignupSubmit,
  watch,
}: SignUpFormProps) => {
  const username = watch("username");
  const email = watch("email");
  const password = watch("password");
  const verifyPassword = watch("verifyPassword");

  const isFormValid =
    username &&
    email &&
    password &&
    verifyPassword &&
    password === verifyPassword;

  return (
    <form
      onSubmit={handleSubmit(handleSignupSubmit)}
      className="bg-white mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
    >
      <h1 className="text-xl text-transparent font-extrabold text-gray-900 sm:text-2xl md:text-3xl text-center bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text">
        LUMIR SARDIP PLATFORM
      </h1>
      <p className="text-center text-lg font-medium text-gray-600 pb-4">
        Welcome! Sign Up to your account
      </p>

      <LoginInput
        text={"Enter Username"}
        type={"text"}
        register={register("username")}
        error={errors.username?.message as string}
      />
      {username && <UsernameValidation username={username} />}

      <LoginInput
        text="Enter Email"
        type="email"
        register={register("email")}
        error={errors.email?.message as string}
      />
      {email && <EmailValidation email={email} />}

      <LoginInput
        text="Enter Password"
        type="password"
        register={register("password")}
        error={errors.password?.message as string}
      />
      {password && <SignupPasswordConfirm passwordValue={password} />}

      <LoginInput
        text="Verify Password"
        type="password"
        register={register("verifyPassword")}
        error={errors.verifyPassword?.message as string}
      />
      <PasswordMatchValidation
        password={password}
        verifyPassword={verifyPassword}
      />

      <button
        type="submit"
        className={`flex justify-center items-center w-full rounded-lg px-5 py-3 text-sm font-medium text-white transition duration-300 ${
          isFormValid && !isSubmitting
            ? "bg-indigo-600 hover:bg-indigo-700 cursor-pointer"
            : "bg-gray-400 cursor-not-allowed"
        }`}
        disabled={!isFormValid || isSubmitting}
      >
        {isSubmitting ? <Loader style="size-5" /> : "Sign Up"}
      </button>

      <p className="text-center text-sm text-gray-400">
        Have account?
        <span
          className="underline cursor-pointer"
          onClick={() => toggleIsSignUp(false)}
        >
          Sign in
        </span>
      </p>
    </form>
  );
};

export default SignUpForm;
