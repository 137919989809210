import Image from "next/image";
import LUMIR_WHITE_LOGO from "~/assets/lumir-logo-white.png";

import { useState } from "react";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignupForm";
import SignupConfirm from "./SignupConfirm";
import { z } from "zod";
import { FieldValues, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import fetchAPI from "@/helpers/fetch";
import { SignupResponse } from "@repo/common/dto";
import ForgotPasswordForm from "@/app/api/auth/signin/components/ForgotPasswordForm";
import { createToast } from "@/utils/createToast";
import { ToastType } from "@/common/components/Toast/Toast";

const signUpSchema = z
  .object({
    username: z
      .string()
      .max(20, "❌ Username is too long")
      .regex(
        /^[a-z0-9가-힣]*$/,
        "❌ Username can only contain lowercase letters and numbers",
      ),
    email: z.string().email("❌ Invalid email"),
    password: z.string().min(8, "❌ Password must be at least 8 characters"),
    verifyPassword: z.string(),
  })
  .refine((data) => data.password === data.verifyPassword, {
    message: "❌ Passwords must match",
    path: ["verifyPassword"],
  });

const LoginContainer = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordForget, setIsPasswordForget] = useState(false);
  const [isSignUpSubmitted, setIsSignUpSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(signUpSchema),
  });

  const passwordValue = watch("password", "");

  const handleSignupSubmit = async (data: FieldValues): Promise<void> => {
    try {
      const response = await fetchAPI<SignupResponse>("/api/auth/register", {
        method: "POST",
        body: JSON.stringify({
          email: data.email,
          userName: data.username,
          password: data.password,
        }),
      });
    } catch (error) {
      createToast(ToastType.ERROR, error.message);
      return;
    }

    // console.log("회원가입 성공:", response.data.items[0]);
    setIsSignUpSubmitted(true);
    reset();
  };

  return (
    <div className="rounded-md w-full px-4 py-12 sm:px-6 sm:py-16 lg:w-1/2 lg:px-8 lg:py-24 flex-1">
      <div className="mx-auto max-w-lg text-center">
        <header>
          <Image
            src={LUMIR_WHITE_LOGO}
            alt={"lumir logo"}
            width={300}
            height={200}
            className={"mx-auto mb-6"}
          />
        </header>
      </div>

      {isPasswordForget ? (
        <ForgotPasswordForm toggleIsPasswordForget={setIsPasswordForget} />
      ) : isSignUp ? (
        isSignUpSubmitted ? (
          <SignupConfirm toggleIsSignUp={setIsSignUp} />
        ) : (
          <SignUpForm
            toggleIsSignUp={setIsSignUp}
            register={register}
            handleSubmit={handleSubmit}
            watch={watch}
            errors={errors}
            isSubmitting={isSubmitting}
            passwordValue={passwordValue}
            handleSignupSubmit={handleSignupSubmit}
          />
        )
      ) : (
        <LoginForm
          toggleIsSignUp={setIsSignUp}
          toggleIsPasswordForget={setIsPasswordForget}
        />
      )}
    </div>
  );
};

export default LoginContainer;
