import React, { forwardRef } from "react";

type LoginInputProps = {
  text: string;
  icon?: React.ReactNode;
  type: "text" | "tel" | "email" | "password";
  register: any; // react-hook-form의 register 함수를 받습니다
  error?: any; // 에러 메시지를 받습니다
};

const LoginInput = forwardRef<HTMLInputElement, LoginInputProps>(
  ({ text, icon, type, register, error }, ref) => {
    return (
      <div className={"w-full"}>
        <label
          htmlFor="Email"
          className="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600 w-full"
        >
          <input
            type={type}
            id={text}
            {...register}
            className="peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm text-gray-600"
            placeholder={text}
          />
          <span className="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white py-0.5 px-1 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs rounded-lg">
            {text}
          </span>
          <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
            {icon && icon}
          </span>
        </label>
        {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
      </div>
    );
  },
);

export default LoginInput;
