import { zodResolver } from '@hookform/resolvers/zod'
import { Loader } from '@repo/common/Loading'
import { ToastType } from '@repo/common/components/Toast'
import createToast from '@repo/common/utils/toast'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import LoginInput from './LoginInput'
import fetchAPI from '@/helpers/fetch'

export type AuthFormProps = {
  toggleIsSignUp: (isSignUp: boolean) => void
  toggleIsPasswordForget: (isPasswordForget: boolean) => void
}

const loginSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters'),
})

type LoginFormData = z.infer<typeof loginSchema>

const LoginForm = ({
  toggleIsSignUp,
  toggleIsPasswordForget,
}: AuthFormProps) => {
  const [isRedirecting, setIsRedirecting] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    watch,
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
    mode: 'onChange',
  })

  const email = watch('email')
  const password = watch('password')

  const onLoginSubmit = async (data: LoginFormData): Promise<void> => {
    setIsRedirecting(true)
    try {
      const response = await fetchAPI('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      // console.log(response);

      if (response.success) {
        // console.log("Login successful");
        window.location.href = '/'
      } else {
        // console.log("response", response);
        throw new Error(response.message)
      }
    } catch (error) {
      createToast(ToastType.ERROR, error.message)
      setIsRedirecting(false)
    }
  }

  const isButtonDisabled =
    !isValid || !email || !password || isSubmitting || isRedirecting

  return (
    <form
      onSubmit={handleSubmit(onLoginSubmit)}
      className="bg-white mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
    >
      <h1 className="text-xl text-transparent font-extrabold text-gray-900 sm:text-2xl md:text-3xl text-center bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text">
        LUMIR SARDIP PLATFORM
      </h1>
      <p className="text-center text-lg font-medium text-gray-600">
        Sign in to your account
      </p>

      <LoginInput
        text="Enter Email"
        type="email"
        register={register('email')}
        error={errors.email?.message}
      />
      <LoginInput
        text="Enter Password"
        type="password"
        register={register('password')}
        error={errors.password?.message}
      />

      <button
        type="submit"
        className={`flex justify-center items-center w-full rounded-lg px-5 py-3 text-sm font-medium text-white transition duration-300 ${
          isButtonDisabled
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-indigo-600 hover:bg-indigo-700'
        }`}
        disabled={isButtonDisabled}
      >
        {isSubmitting || isRedirecting ? <Loader style="size-5" /> : 'Sign In'}
      </button>

      <p className="text-center text-sm text-gray-400">
        No account?{'  '}
        <span
          className="underline cursor-pointer"
          onClick={() => toggleIsSignUp(true)}
        >
          Sign Up
        </span>
      </p>
      <p className="text-center text-sm text-gray-400 mt-0">
        Forgot Password?{'  '}
        <span
          className="underline cursor-pointer"
          onClick={() => toggleIsPasswordForget(true)}
        >
          Send Temporary Password
        </span>
      </p>
    </form>
  )
}

export default LoginForm
