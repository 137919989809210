import { Slide, toast, ToastPosition } from "react-toastify";

import Toast, { ToastType } from "@/common/components/Toast/Toast";
import { TOAST_CONSTANTS } from "@repo/common/constants";

const toastOptions = {
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Slide,
  position: "top-center" as ToastPosition,
};

export const createToast = (type: ToastType, content: string): void => {
  const customToastStyle =
    type === ToastType.SUCCESS
      ? TOAST_CONSTANTS.success.style
      : type === ToastType.ERROR
        ? TOAST_CONSTANTS.error.style
        : TOAST_CONSTANTS.search.style;

  toast(<Toast type={type} content={content} />, {
    ...toastOptions,
    style: customToastStyle,
  });
};
