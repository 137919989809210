"use client";

import Script from "next/script";

import { motion } from "framer-motion";
import LoadingBG from "./loading-bg";
import LoginContainer from "./components/LoginContainer";
import useLoaded from "@/app/api/auth/signin/hooks/useLoaded";

const SignInPage = () => {
  const loaded = useLoaded();

  return (
    <div className="relative">
      {!loaded ? (
        <LoadingBG />
      ) : (
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="scrollbar-hide text relative flex max-w-[1920px] select-none flex-col"
        >
          <section className="absolute sm:w-[300px] lg:left-[50px] flex h-screen max-w-screen-xl items-center justify-center px-4 text-white md:w-[400px] lg:w-[500px] xl:w-[600px]">
            <div className="mx-auto flex justify-center w-full">
              <LoginContainer />
            </div>
          </section>
        </motion.div>
        // ? Three.js를 불러옵니다.
      )}
      <Script src="/bundle.js" />
    </div>
  );
};

export default SignInPage;
