const SignupConfirm = ({ toggleIsSignUp }) => {
  return (
    <div className="bg-white mb-0 mt-6 space-y-6 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8">
      <h1 className="my-6 text-lg font-bold text-gray-900 sm:text-xl md:text-2xl">
        Thank you for Signup!
      </h1>
      <span className="text-gray-700">
        Please check your <span className="font-bold">email</span> to confirm
        your account.
      </span>
      <button
        type="button"
        className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
        onClick={() => toggleIsSignUp(false)}
      >
        Sign In
      </button>
    </div>
  );
};

export default SignupConfirm;
