import React from "react";
import { Check, MapSearch, NormalClose, AlertCircle } from "@repo/ui/icons";

export enum ToastType {
  SUCCESS,
  ERROR,
  WARN,
  SEARCH,
}

const icons = [
  {
    type: ToastType.SUCCESS,
    icon: <Check width={12} height={12} />,
    iconStyle:
      "bg-palette-green p-1 rounded-full text-white flex items-center justify-center",
    textStyle: "text-gray-500",
  },
  {
    type: ToastType.ERROR,
    icon: <NormalClose width={14} height={14} />,
    iconStyle:
      "bg-palette-red p-1 rounded-full text-white flex items-center justify-center",
    textStyle: "text-gray-500",
  },
  {
    type: ToastType.WARN,
    icon: <AlertCircle width={14} height={14} />,
    iconStyle:
      "bg-palette-yellow p-1 rounded-full text-white flex items-center justify-center",
    textStyle: "text-gray-500",
  },
  {
    type: ToastType.SEARCH,
    icon: <MapSearch width={14} height={14} />,
    iconStyle:
      "bg-palette-cyan p-1 rounded-full text-white flex items-center justify-center",
    textStyle: "text-gray-500",
  },
];

interface RequestToast {
  type: ToastType;
  content: string;
}

const Toast = ({ type, content }: RequestToast) => {
  const { icon, iconStyle, textStyle } = icons[type];

  return (
    <div className="flex w-fit items-center gap-2 rounded-[5px] py-0">
      <span className={iconStyle}>{icon}</span>
      <span className={`text-[14px] font-bold ${textStyle}`}>{content}</span>
    </div>
  );
};

export default Toast;
