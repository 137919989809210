import { useEffect, useState } from "react";

const useLoaded = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const handleEverythingLoaded = () => {
      setLoaded(true);

      // ? bundle.js를 불러온다면 canvas는 무조건 있습니다.

      const canvas = document.querySelector("canvas")!;
      canvas.style.display = "block";
      canvas.style.opacity = "1";
    };

    window.addEventListener("everythingLoaded", handleEverythingLoaded);

    return () => {
      window.removeEventListener("everythingLoaded", handleEverythingLoaded);
    };
  }, []);

  return loaded;
};

export default useLoaded;
